import { Outlet } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollToTop from '../src/helpers/ScrollToTop.js';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />

    </div>
  );
}

export default App;
