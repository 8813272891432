import React from 'react';
import './index.css';

const Services = () => {

    const ourServices = [
        {
            heading: "CONSTRUCTION",
            desc: "Consistent, on-time and on-budget"
        },
        {
            heading: "CONCEPT DRAWING",
            desc: "Commercial & residential projects"
        },
        {
            heading: "INTERIOR DECORATION",
            desc: "Customized designs to suit your needs"
        },
        {
            heading: "FIT OUT SOLUTIONS",
            desc: "High quality materials & installation"
        },
        {
            heading: "RENOVATIONS & REMODELING",
            desc: "Executed to the highest standards"
        },
        {
            heading: "MEP",
            desc: "Trained experts & guaranteed results"
        },
    ];

    const whatWeDo = [
        {
            heading: "JOINERY WORKS",
            desc: "Our trained joiners build high standard quality furniture and laminates such as doors, cabinets, and fire rated doors. We specialize in all kinds of wood, including Meranti, Teak, Veneers, Maple, Oak, and Mahogany."
        },
        {
            heading: "GYPSUM WORKS",
            desc: "We offer all kinds of gypsum works from plastering to installation of gypsum units, boards, and partitions for standard and customized wall and ceiling designs."
        },
        {
            heading: "WALL FINISHING",
            desc: "We offer standard as well as customized wall finishing, such as wallpapers, custom paining, Stucco effect, painted murals, wall tiles, vinyl and more."
        },
        {
            heading: "GLASS & FLOOR WORKS",
            desc: "We install all kinds of glass materials and flooring finish materials including all-glass doors, mirrors, tiles, carpets, parquet, marble, vinyl, composite, laminate, and hardwood."
        },
        {
            heading: "ALUMINUM WORKS",
            desc: "Our interior decoration service also extends to aluminum works, offering standard and custom-made curtains, wall cladding, cupboards, railing, staircase, walkways, louvres, and other fittings."
        },
        {
            heading: "STAINLESS STEEL & WROUGHT IRON",
            desc: "Depending on our client’s requirements, we offer custom and standard stainless steel and wrought iron works including furniture, kitchen equipment, sink & fittings, handrails, railing, doors, windows, and roof."
        },
        {
            heading: "CIVIL",
            desc: "Professional engineers who deal with design, construction and maintenance of the physical and naturally built environment, including roads, access points, entrances and exits in and around your property or storefront."
        },
    ];

    const introData = {
        heading1: "OUR DIVISIONS",
        heading2: "OUR OBJECTIVES",
        heading3: "OUR STAFF & STRENGTH",
        divsions: [
          "CIVIL CONSTRUCTION",
          "MEP DESIGN & WORK EXECUTION",
          "INTERIOR DESIGN & DECORATION",
          "METAL FABRICATION WORKS",
          "HVAC DESIGN & WORK EXECUTION",
          "EXTERIOR FACADE DESIGN & EXECUTION"
        ],
        divsionsDesc: "These divisions cooperate either together to execute tunrkey projects or independently to execute projects in their respective specialities keeping in view of the experience, expertise, financial resources, and equipment, we are capable and confident to undertake any civil Engineering construction project of substantial magnitude. In this regard, we enclosing a brief profile for your consideration.",
        objectiveDesc: "Our aim to provide better services and quality of work for our customers at any site whenever  they want and we will complete the projects within the stipulated time frame mentioned. We have sufficient manpower and our own technical team to execute multiple projects simultaneously with respect to the working standards and details. We also have our own warehouse and workshop, machinery & equipment for fabrication works, and manufacture of shop settings, Carpentry items as per requirements. We have executed all kind of works in several retail outlet projects which includes Civil Works (Flooring works, Gypsum/MDF partition, Ceiling woks, Painting and finishing works, Supply and installation of shop settings and Execution of exterior façade designs) MEP Works like Electrical, Fire fighting, and HVAC works",
        stafstr: [
          {
            no: 3,
            content: "Project/Site Engineers"
          },
          {
            no: 7,
            content: "Site Supervisors"
          },
          {
            no: 2,
            content: "Estimation Surveyor"
          },
          {
            no: 2,
            content: "Design & Draftsman"
          },
          {
            no: 25,
            content: "Carpenters"
          },
          {
            no: 20,
            content: "Painters"
          },
          {
            no: 18,
            content: "Tile workers"
          },
          {
            no: 20,
            content: "Ceiling worker"
          },
          {
            no: 8,
            content: "Welders"
          },
          {
            no: 25,
            content: "MEP worker"
          },
          {
            no: 35,
            content: "Unskilled Labors"
          },
        ]
      }


  return (
    <div className='Services-container'>
        <div className="Services-ourServices-section">
            <div className="Services-ourServices-section-texts">
                <h1 className="headingBorderBottom">OUR SERVICES</h1>
                <p>With 12 years of combined experience in interior design and construction Hue Construction & Interiors delivers excellence in every stage. From space layout planning and conceptual drawing to interior decoration and installations our team guarantees consistent, on-time and on-budget project execution.</p>
            </div>
            <div className="Services-ourServices-section-cards">
                {
                    ourServices.map((service,i)=>(
                        <div key={i} className="Services-ourServices-section-card">
                            <p>{service.heading}</p>
                            <p>{service.desc}</p>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className="Services-whatwedo-section">
            <div className="Services-whatwedo-section-content">
                <h1 className="headingBorderBottom">WHAT WE DO</h1>
                <div className="Services-whatwedo-section-content-cards">
                    {whatWeDo.map((data,i)=>(
                        <div key={i} className="Services-whatwedo-section-content-card">
                            <p>{data.heading}</p>
                            <p>{data.desc}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="Services-staffStrenth-section">
            <h3 className='headingBorderBottom'>{introData.heading3}</h3>
            <div className="staffStrenghtCards">
            {introData.stafstr.map((data,i)=>(
                <div className="staffStrenghtCard" key={i}>
                <span>{data.no}</span><p>{data.content}</p>
                </div>
            ))}
            </div>
        </div>
    </div>
  )
}

export default Services