import React, { useRef } from "react";
// import EmblaCarouselThumbnails from '../../components/EmblaCarouselThumbnails';
import "./index.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import centerpoint1 from "../../assets/images/centerpoint/centrepoint1.jpeg";
import centerpoint2 from "../../assets/images/centerpoint/centrepoint2.jpeg";
import centerpoint3 from "../../assets/images/centerpoint/centrepoint3.jpeg";
import centerpoint4 from "../../assets/images/centerpoint/centrepoint4.jpeg";
import centerpoint5 from "../../assets/images/centerpoint/centrepoint5.jpeg";
import centerpoint6 from "../../assets/images/centerpoint/centrepoint6.jpeg";
import centerpoint7 from "../../assets/images/centerpoint/centrepoint7.jpeg";
import centerpoint8 from "../../assets/images/centerpoint/centrepoint8.jpeg";
import centerpoint9 from "../../assets/images/centerpoint/centrepoint9.jpeg";
import centerpoint10 from "../../assets/images/centerpoint/centrepoint10.jpeg";
import centerpoint11 from "../../assets/images/centerpoint/centrepoint11.jpeg";
import centerpoint12 from "../../assets/images/centerpoint/centrepoint12.jpeg";
import centerpoint13 from "../../assets/images/centerpoint/centrepoint13.jpeg";

// import alfuttaim1 from "../../assets/images/alfuttaim/alfuttaim1.jpg";
// import alfuttaim2 from "../../assets/images/alfuttaim/alfuttaim2.jpg";
// import alfuttaim3 from "../../assets/images/alfuttaim/alfuttaim3.jpg";
// import alfuttaim4 from "../../assets/images/alfuttaim/alfuttaim4.jpg";
// import alfuttaim5 from "../../assets/images/alfuttaim/alfuttaim5.jpg";
// import alfuttaim6 from "../../assets/images/alfuttaim/alfuttaim6.jpg";
import alfuttaim7 from "../../assets/images/alfuttaim/alfuttaim7.jpeg";
import alfuttaim8 from "../../assets/images/alfuttaim/alfuttaim8.jpeg";
import alfuttaim9 from "../../assets/images/alfuttaim/alfuttaim9.jpeg";
import alfuttaim10 from "../../assets/images/alfuttaim/alfuttaim10.jpeg";
import alfuttaim11 from "../../assets/images/alfuttaim/alfuttaim11.jpeg";
import alfuttaim12 from "../../assets/images/alfuttaim/alfuttaim12.jpeg";
import alfuttaim13 from "../../assets/images/alfuttaim/alfuttaim13.jpeg";

import rb1 from "../../assets/images/r&b/r&b1.jpeg";
import rb2 from "../../assets/images/r&b/r&b2.jpeg";
import rb3 from "../../assets/images/r&b/r&b3.jpeg";
import rb4 from "../../assets/images/r&b/r&b4.jpeg";
import rb5 from "../../assets/images/r&b/r&b5.jpeg";
import rb6 from "../../assets/images/r&b/r&b6.jpeg";
import rb7 from "../../assets/images/r&b/r&b7.jpeg";
import rb8 from "../../assets/images/r&b/r&b8.jpeg";
import rb9 from "../../assets/images/r&b/r&b9.jpeg";
import rb10 from "../../assets/images/r&b/r&b10.jpeg";
import rb11 from "../../assets/images/r&b/r&b11.jpeg";
import rb12 from "../../assets/images/r&b/r&b12.jpeg";
import rb13 from "../../assets/images/r&b/r&b13.jpeg";

import redTag1 from "../../assets/images/redTag/redTag01.jpg";
import redTag2 from "../../assets/images/redTag/redTag02.jpg";
import redTag3 from "../../assets/images/redTag/redTag03.jpg";
import redTag4 from "../../assets/images/redTag/redTag04.jpg";
import redTag5 from "../../assets/images/redTag/redTag05.jpg";
import redTag6 from "../../assets/images/redTag/redTag06.jpg";
import redTag7 from "../../assets/images/redTag/redTag07.jpg";
import redTag8 from "../../assets/images/redTag/redTag08.jpg";
import redTag9 from "../../assets/images/redTag/redTag09.jpg";
import redTag10 from "../../assets/images/redTag/redTag10.jpg";
import redTag11 from "../../assets/images/redTag/redTag11.jpg";
import redTag12 from "../../assets/images/redTag/redTag12.jpg";
import redTag13 from "../../assets/images/redTag/redTag13.jpg";
import redTag14 from "../../assets/images/redTag/redTag13.jpg";
import redTag15 from "../../assets/images/redTag/redTag13.jpg";
import redTag16 from "../../assets/images/redTag/redTag13.jpg";
import redTag17 from "../../assets/images/redTag/redTag13.jpg";
import redTag18 from "../../assets/images/redTag/redTag13.jpg";
import redTag19 from "../../assets/images/redTag/redTag13.jpg";
import redTag20 from "../../assets/images/redTag/redTag13.jpg";
import redTag21 from "../../assets/images/redTag/redTag13.jpg";
import redTag22 from "../../assets/images/redTag/redTag13.jpg";
import redTag23 from "../../assets/images/redTag/redTag13.jpg";
import redTag24 from "../../assets/images/redTag/redTag13.jpg";
import redTag25 from "../../assets/images/redTag/redTag13.jpg";
import redTag26 from "../../assets/images/redTag/redTag13.jpg";
import redTag27 from "../../assets/images/redTag/redTag13.jpg";
import redTag28 from "../../assets/images/redTag/redTag13.jpg";

import yoyosoJp1 from "../../assets/images/yoyosoJp/yoyosoJp01.jpg";
import yoyosoJp2 from "../../assets/images/yoyosoJp/yoyosoJp02.jpg";
import yoyosoJp3 from "../../assets/images/yoyosoJp/yoyosoJp03.jpg";
import yoyosoJp4 from "../../assets/images/yoyosoJp/yoyosoJp04.jpg";
import yoyosoJp5 from "../../assets/images/yoyosoJp/yoyosoJp05.jpg";
import yoyosoJp6 from "../../assets/images/yoyosoJp/yoyosoJp06.jpg";
import yoyosoJp7 from "../../assets/images/yoyosoJp/yoyosoJp07.jpg";
import yoyosoJp8 from "../../assets/images/yoyosoJp/yoyosoJp08.jpg";
import yoyosoJp9 from "../../assets/images/yoyosoJp/yoyosoJp09.jpg";
import yoyosoJp10 from "../../assets/images/yoyosoJp/yoyosoJp10.jpg";
import yoyosoJp11 from "../../assets/images/yoyosoJp/yoyosoJp11.jpg";
import yoyosoJp12 from "../../assets/images/yoyosoJp/yoyosoJp12.jpg";
import yoyosoJp13 from "../../assets/images/yoyosoJp/yoyosoJp12.jpg";
import yoyosoJp14 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp15 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp16 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp17 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp18 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp19 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp20 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp21 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp22 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp23 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp24 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp25 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp26 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp27 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp28 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp29 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp30 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp31 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp32 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp33 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp34 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";
import yoyosoJp35 from "../../assets/images/yoyosoJp/yoyosoJp13.jpg";

import homeBox1 from "../../assets/images/homeBox/homeBox01.jpg";
import homeBox2 from "../../assets/images/homeBox/homeBox02.jpg";
import homeBox3 from "../../assets/images/homeBox/homeBox03.jpg";
import homeBox4 from "../../assets/images/homeBox/homeBox04.jpg";
import homeBox5 from "../../assets/images/homeBox/homeBox05.jpg";
import homeBox6 from "../../assets/images/homeBox/homeBox06.jpg";
import homeBox7 from "../../assets/images/homeBox/homeBox07.jpg";
import homeBox8 from "../../assets/images/homeBox/homeBox08.jpg";
import homeBox9 from "../../assets/images/homeBox/homeBox09.jpg";
import homeBox10 from "../../assets/images/homeBox/homeBox10.jpg";
import homeBox11 from "../../assets/images/homeBox/homeBox11.jpg";
import homeBox12 from "../../assets/images/homeBox/homeBox12.jpg";

import maxSalam1 from "../../assets/images/maxSalam/maxSalam01.jpg";
import maxSalam2 from "../../assets/images/maxSalam/maxSalam02.jpg";
import maxSalam3 from "../../assets/images/maxSalam/maxSalam03.jpg";
import maxSalam4 from "../../assets/images/maxSalam/maxSalam04.jpg";
import maxSalam5 from "../../assets/images/maxSalam/maxSalam05.jpg";
import maxSalam6 from "../../assets/images/maxSalam/maxSalam06.jpg";
import maxSalam7 from "../../assets/images/maxSalam/maxSalam07.jpg";
import maxSalam8 from "../../assets/images/maxSalam/maxSalam08.jpg";
import maxSalam9 from "../../assets/images/maxSalam/maxSalam09.jpg";
import maxSalam10 from "../../assets/images/maxSalam/maxSalam10.jpg";
import maxSalam11 from "../../assets/images/maxSalam/maxSalam11.jpg";
import maxSalam12 from "../../assets/images/maxSalam/maxSalam12.jpg";
import maxSalam13 from "../../assets/images/maxSalam/maxSalam12.jpg";
import maxSalam14 from "../../assets/images/maxSalam/maxSalam12.jpg";
import maxSalam15 from "../../assets/images/maxSalam/maxSalam12.jpg";
import maxSalam16 from "../../assets/images/maxSalam/maxSalam12.jpg";
import maxSalam17 from "../../assets/images/maxSalam/maxSalam12.jpg";
// import useOnScreen from '../../helpers/useOnScreen';
import ProjectSection from "../../components/ProjectSection";

const Projects = () => {
  const projectsData = [
    {
      heading: "CENTER POINT",
      content: [
        centerpoint1,
        centerpoint2,
        centerpoint3,
        centerpoint4,
        centerpoint5,
        centerpoint6,
        centerpoint7,
        centerpoint8,
        centerpoint9,
        centerpoint10,
        centerpoint11,
        centerpoint12,
        centerpoint13,
      ],
    },
    {
      heading: "AL FUTTAIM GROUP",
      content: [
        // alfuttaim1,
        // alfuttaim2,
        // alfuttaim3,
        // alfuttaim4,
        // alfuttaim5,
        // alfuttaim6,
        alfuttaim7,
        alfuttaim8,
        alfuttaim9,
        alfuttaim10,
        alfuttaim11,
        alfuttaim12,
        alfuttaim13,
      ],
    },
    {
      heading: "Red Tag",
      content: [
        redTag1,
        redTag2,
        redTag3,
        redTag4,
        redTag5,
        redTag6,
        redTag7,
        redTag8,
        redTag9,
        redTag10,
        redTag11,
        redTag12,
        redTag13,
        redTag14,
        redTag15,
        redTag16,
        redTag17,
        redTag18,
        redTag19,
        redTag20,
        redTag21,
        redTag22,
        redTag23,
        redTag24,
        redTag25,
        redTag26,
        redTag27,
        redTag28,
      ],
    },
    {
      heading: "R&B",
      content: [
        rb1,
        rb2,
        rb3,
        rb4,
        rb5,
        rb6,
        rb7,
        rb8,
        rb9,
        rb10,
        rb11,
        rb12,
        rb13,
      ],
    },
    {
      heading: "Yoyoso Jp",
      content: [
        yoyosoJp1,
        yoyosoJp2,
        yoyosoJp3,
        yoyosoJp4,
        yoyosoJp5,
        yoyosoJp6,
        yoyosoJp7,
        yoyosoJp8,
        yoyosoJp9,
        yoyosoJp10,
        yoyosoJp11,
        yoyosoJp12,
        yoyosoJp13,
        yoyosoJp14,
        yoyosoJp15,
        yoyosoJp16,
        yoyosoJp17,
        yoyosoJp18,
        yoyosoJp19,
        yoyosoJp20,
        yoyosoJp21,
        yoyosoJp22,
        yoyosoJp23,
        yoyosoJp24,
        yoyosoJp25,
        yoyosoJp26,
        yoyosoJp27,
        yoyosoJp28,
        yoyosoJp29,
        yoyosoJp30,
        yoyosoJp31,
        yoyosoJp32,
        yoyosoJp33,
        yoyosoJp34,
        yoyosoJp35,
      ],
    },
    {
      heading: "Home Box",
      content: [
        homeBox1,
        homeBox2,
        homeBox3,
        homeBox4,
        homeBox5,
        homeBox6,
        homeBox7,
        homeBox8,
        homeBox9,
        homeBox10,
        homeBox11,
        homeBox12,
      ],
    },
    {
      heading: "Max Salam Mall",
      content: [
        maxSalam1,
        maxSalam2,
        maxSalam3,
        maxSalam4,
        maxSalam5,
        maxSalam6,
        maxSalam7,
        maxSalam8,
        maxSalam9,
        maxSalam10,
        maxSalam11,
        maxSalam12,
        maxSalam13,
        maxSalam14,
        maxSalam15,
        maxSalam16,
        maxSalam17,
      ],
    },
  ];

  const OPTIONS = {};
  // useOnScreen();
  // const ref = useRef();
  return (
    <div className="Projects-container">
      {projectsData.map((data, i) => (
        // <div className="Project-section">
        //     <h3 className='headingBorderBottom'>{data.heading}</h3>
        //     <Carousel>
        //     {/* <div className="Projects-carousel"> */}
        //         {data.content.map((img)=>(
        //             <div>
        //                 <img src={img} alt="hahahah" />
        //             </div>
        //         ))}
        //         {/* <EmblaCarouselThumbnails slides={data.content} options={OPTIONS}/> */}
        //     {/* </div> */}
        //     </Carousel>
        // </div>
        <ProjectSection data={data} />
      ))}
    </div>
  );
};

export default Projects;
