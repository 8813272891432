import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './index.css';
import {GiHamburgerMenu} from 'react-icons/gi';
import {ImCross} from 'react-icons/im';

const Header = () => {
  const [showMenu, setshowMenu] = useState(false);
  return (
    <header className='Header-container'>
    <div className="Header-content">
    <img src="/HueLogo.jpg" alt="companyLogo" />
    {/* <ul class="snip1217">
    <li class="current"><a href="#">Home</a></li>
    <li><a href="#">About Us</a></li>
    <li><a href="#">Blog</a></li>
    <li><a href="#">Services</a></li>
    <li><a href="#">Products</a></li>
    <li><a href="#">Contact</a></li>
    </ul> */}

    <div className="Header-options">
    <NavLink to='/' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Home</NavLink>
    <NavLink to='/about' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>About</NavLink>
    <NavLink to='/projects' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Projects</NavLink>
    <NavLink to='/services' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Services</NavLink>
    <NavLink to='/contact' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Contact</NavLink>
    </div>
    {showMenu ? <ImCross className='menuIcon' onClick={()=> setshowMenu(false)}  /> : <GiHamburgerMenu className='menuIcon' onClick={()=> setshowMenu(true)} />}
    {/* <div className="Header-responsive-content"> */}
    {showMenu ? <div className="Header-responsive-options">
    <NavLink to='/' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Home</NavLink>
    <NavLink to='/about' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>About</NavLink>
    <NavLink to='/projects' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Projects</NavLink>
    <NavLink to='/services' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Services</NavLink>
    <NavLink to='/contact' className={({ isActive }) => isActive ? "Header-option-active-class" : undefined}>Contact</NavLink>
    </div> : null}
    {/* </div> */}
    </div>
    </header>
    )
  }
  
  export default Header