import React from 'react';
import './index.css';

const Footer = () => {

  const footerData = [
    {
      heading: "Location",
      content: [
        "6933 Al Aliyah, Al Rehab, Jeddah 23343, Saudi Arabia"
      ]
    },
    {
      heading: "Phone",
      content: [
        "+ 966502590825",
        "+ 966540521413"
      ]
    },
    {
      heading: "Mails",
      content: [
        "mohdamjad83@yahoo.com",
        "high_unitedest@yahoo.com",
        "projects@highunitedest.com",
        "m.amjad@highunitedest.com"
      ]
    }
  ];

  return (
    <footer className='Footer-container'>
      <div className="Footer-content">
            <h3>Contact Us</h3>
            <div className="Footer-contact-options">
              {
                footerData.map((data)=>(
                <div className="Footer-contact-option" key={data.heading}>
                <p>{data.heading}</p>
                <div className="Footer-contact-texts">
                  {
                    data.content.map((content)=>(
                      <p key={content}>{content}</p>
                    ))
                  }
                </div>
              </div>
              ))
              }
              {/* <div className="Footer-contact-option">
                <p>Subscribe</p>
                <div className="Footer-contact-input">
                    <input type="text" placeholder='Enter your mail' />
                    <button>Subscribe</button>
                </div>
              </div> */}
            </div>
            <div className="Footer-copyright-section">
              © 2023 All copyrights reserved by HUE
            </div>
      </div>
    </footer>
  )
}

export default Footer