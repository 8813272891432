import React from "react";
import "./index.css";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

// url to a valid topojson file
// const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const Contact = () => {
  return (
    <div className="Contact-container">
      <div className="contact-details-section">
        <div className="contact-details-section-left">
          <div>
            <h5>Location</h5>
            <p>6933 Al Aliyah, Al Rehab, Jeddah 23343, Saudi Arabia</p>
          </div>
          <div>
            <h5>Phone</h5>
            <p>+ 966502590825</p>
            <p>+ 966540521413</p>
          </div>
          <div>
            <h5>Mails</h5>
            <p>mohdamjad83@yahoo.com</p>
            <p>high_unitedest@yahoo.com</p>
            <p>projects@highunitedest.com</p>
            <p>m.amjad@highunitedest.com</p>
          </div>
        </div>
        <div className="contact-details-section-right">
          <ComposableMap>
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography key={geo.rsmKey} geography={geo} />
                ))
              }
            </Geographies>
          </ComposableMap>
        </div>
      </div>
    </div>
  );
};

export default Contact;
