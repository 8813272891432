import React, { useRef } from 'react';
import LazyLoad from 'react-lazy-load';
import { Carousel } from 'react-responsive-carousel';
// import useOnScreen from '../../helpers/useOnScreen';
import './index.css';

const ProjectSection = ({data}) => {
    // const ref = useRef()
    // const isVisible = useOnScreen(ref);
  return (
      <div className="Project-section">
    
        {/* <LazyLoad threshold={0.95}> */}
            {/* <> */}
            <h3 className='headingBorderBottom'>{data.heading}</h3>
        {/* {isVisible ? */}
            <Carousel >
            {/* <div className="Projects-carousel"> */}
                {data.content.map((img)=>(
                    <div>
                        <img src={img} alt="projectImg" />
                    </div>
                ))}
                {/* <EmblaCarouselThumbnails slides={data.content} options={OPTIONS}/> */}
            {/* </div> */}
            </Carousel>
             {/* : null} */}
             {/* </> */}
{/* </LazyLoad> */}
</div>
  )
}

export default ProjectSection