import React from 'react';
import CountUp from 'react-countup';
import './index.css';

const About = () => {
  const introData = {
    heading1: "OUR DIVISIONS",
    heading2: "OUR OBJECTIVES",
    heading3: "OUR STAFF & STRENGTH",
    divsions: [
      "CIVIL CONSTRUCTION",
      "MEP DESIGN & WORK EXECUTION",
      "INTERIOR DESIGN & DECORATION",
      "METAL FABRICATION WORKS",
      "HVAC DESIGN & WORK EXECUTION",
      "EXTERIOR FACADE DESIGN & EXECUTION"
    ],
    divsionsDesc: "These divisions cooperate either together to execute tunrkey projects or independently to execute projects in their respective specialities keeping in view of the experience, expertise, financial resources, and equipment, we are capable and confident to undertake any civil Engineering construction project of substantial magnitude. In this regard, we enclosing a brief profile for your consideration.",
    objectiveDesc: "Our aim to provide better services and quality of work for our customers at any site whenever  they want and we will complete the projects within the stipulated time frame mentioned. We have sufficient manpower and our own technical team to execute multiple projects simultaneously with respect to the working standards and details. We also have our own warehouse and workshop, machinery & equipment for fabrication works, and manufacture of shop settings, Carpentry items as per requirements. We have executed all kind of works in several retail outlet projects which includes Civil Works (Flooring works, Gypsum/MDF partition, Ceiling woks, Painting and finishing works, Supply and installation of shop settings and Execution of exterior façade designs) MEP Works like Electrical, Fire fighting, and HVAC works",
    stafstr: [
      {
        no: 3,
        content: "Project/Site Engineers"
      },
      {
        no: 7,
        content: "Site Supervisors"
      },
      {
        no: 2,
        content: "Estimation Surveyor"
      },
      {
        no: 2,
        content: "Design & Draftsman"
      },
      {
        no: 25,
        content: "Carpenters"
      },
      {
        no: 20,
        content: "Painters"
      },
      {
        no: 18,
        content: "Tile workers"
      },
      {
        no: 20,
        content: "Ceiling worker"
      },
      {
        no: 8,
        content: "Welders"
      },
      {
        no: 25,
        content: "MEP worker"
      },
      {
        no: 35,
        content: "Unskilled Labors"
      },
    ]
  }
  return (
    <div className='About-container'>
            <h3 className='headingBorderBottom'>{introData.heading1}</h3>
            <div className="About-intro-cards-section">
              {introData.divsions.map((divsion,i)=> <span key={i}>{divsion}</span>)}
            </div>
            <p>{introData.divsionsDesc}</p>
            <div className="About-counters">
              <div className="About-counter-card">
                <p><CountUp start={0} end={15} />+</p>
                <span></span>
                <p>YEARS OF EXPERIENCE</p>
              </div>
              <div className="About-counter-card">
                <p><CountUp start={0} end={100} />+</p>
                <span></span>
                <p>PROJECTS COMPLETED</p>
              </div>
              <div className="About-counter-card">
                <p><CountUp start={0} end={100} />+</p>
                <span></span>
                <p>DEDICATED TEAM MEMBERS</p>
              </div>
            </div>
            <h3 className='headingBorderBottom'>{introData.heading2}</h3>
            <p>{introData.objectiveDesc}</p>
            <h3 className='headingBorderBottom'>{introData.heading3}</h3>
            <div className="staffStrenghtCards">
              {introData.stafstr.map((data,i)=>(
                <div className="staffStrenghtCard" key={i}>
                  <span>{data.no}</span><p>{data.content}</p>
                </div>
              ))}
            </div>
    </div>
  )
}

export default About